<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        top
        right
        transition="slide-x-reverse-transition"
      >
        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>

      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-container fluid>
            <v-card class="mx-auto mb-12">
              <v-container fluid ref="messagesContainer">
                <v-row>
                  <v-col cols="4" sm="4" class="mt-3 v-tab">English</v-col>
                  <v-col cols="8" sm="8" class="mt-3">
                    <validation-provider
                        v-slot="{ errors }"
                        name="English"
                        rules="required|max:1000"
                    >
                      <v-textarea
                        auto-grow
                        rows="1"
                        required
                        v-model="genericMessage.message"
                        :error-messages="errors"
                        maxlength="1000"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>
                <!--        Loop to show all other languages          -->
                <v-row
                  v-for="(languageOption, index) in genericMessage.translations"
                  :key="index"
                >
                  <v-col cols="4" sm="4" class="mt-3 v-tab">
                    {{ languageOption.label }}
                  </v-col>
                  <v-col cols="8" sm="8" class="mt-3">
                    <validation-provider
                        v-slot="{ errors }"
                        :name="languageOption.label"
                        rules="max:1000"
                    >
                      <v-textarea
                        auto-grow
                        rows="1"
                        v-model="languageOption.message"
                        :error-messages="errors"
                        maxlength="1000"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import { mapFields } from "vuex-map-fields";
import {max, required} from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

extend("required", {
  ...required,
  message: "Please provide a value.",
});

extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});

setInteractionMode("passive");
export default {
  name: "GenericMessagesForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      snackbar: false,
      isSubmitting: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    ...mapFields("genericMessages", {
      genericMessage: "genericMessage",
    }),
    ...mapGetters("systemLanguages", {
      secondaryLanguageOptions: "languagesList",
    }),
    ...mapGetters("genericMessages", {}),
    ...mapState("genericMessages", ["error"]),
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setTitleObject",
      "setBreadcrumbs",
    ]),
    ...mapActions("genericMessages", [
      "getGenericMessage",
      "clearGenericMessage",
      "clearGenericMessages",
      "editGenericMessage",
    ]),
    ...mapActions("genericMessages", {}),
    ...mapActions("systemLanguages", {
      getLanguagesList: "getLanguagesList",
    }),
    // SUBMIT FORM
    async submit() {
      if (
          this.isSubmitting ||
          !(await this.$refs.observer.validate())
      ) {
        return;
      }

      let success = false;
      this.isSubmitting = true;
      success = await this.editGenericMessage(this.genericMessage.id);

      if (success !== false) {
        this.clearGenericMessages();

        const redirect = {
          name: "GenericMessagesIndex",
        };
        await this.$router.push(redirect);
      } else {
        this.snackbar = !!this.error;
      }
      this.isSubmitting = false;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    await this.getLanguagesList();
    this.setBreadcrumbs();
    let actionButtons = [];

    await this.getGenericMessage(this.id).catch(() => {
      this.$router.push({ name: "NotFound" });
    });
    this.setTitleObject(
      "Generic Messages (" + this.genericMessage.keyName + ")"
    );
    actionButtons.push({
      component: "CancelButton",
      attributes: {
        to: "/generic-messages",
      },
      index: 0,
    });
    actionButtons.push({
      component: "SaveButton",
      attributes: {
        onSubmit: () => this.submit(),
      },
      index: 1,
    });

    this.setActionButtons(actionButtons);
    this.isLoading = false;
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.clearGenericMessage();
  },
};
</script>
<style scoped></style>
